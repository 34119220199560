// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect } from "react";
import { PlasmicDebtorsList } from "./plasmic/collect_wise_draft/PlasmicDebtorsList";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Configure, useHits } from 'react-instantsearch-hooks-web';
import '@algolia/autocomplete-theme-classic';
import { useAuth } from "../contexts/AuthContext";
import ContractsListCard from "./ContractsListCard";
import { db, storage } from "../firebase"
import { collection, addDoc, doc, setDoc, serverTimestamp, getDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAt, endAt, startAfter, endBefore, limitToLast } from "firebase/firestore";
import MasterClientsListCard from "./MasterClientsListCard";
import { Button, Modal, Form } from "react-bootstrap"
import AddClientModal from "./AddClientModal"
import { v4 } from "uuid";
import Moment from 'moment';
import LoadingSpinner from "./LoadingSpinner";
import LoadingSpinnerWithText from "./LoadingSpinnerWithText";
import axios from "axios";
import { config } from './constants'
import { useHistory } from 'react-router-dom';

const algoliaClient = algoliasearch('FL3TQWNAKP', 'f67b3ce7c011000feed8a7898a75330b');


const searchClient = {

  ...algoliaClient,

  search(requests) {


    if (requests.every(({ params }) => !params.query)) {
      // setOpenContractSearch(false)
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }
    // setOpenContractSearch(true)
    return algoliaClient.search(requests);
  },
};


function CustomHits(props) {
  const { hits, results, sendEvent, query } = useHits();
  const { currentUser, clientHits, setClientHits, openClientSearch, setOpenClientSearch } = useAuth()


  if (typeof results.query === 'undefined') {
    setOpenClientSearch(false)
  } else {
    setOpenClientSearch(true)
  }


  setClientHits(hits)



  return (<>
  </>)

}


function AllDebtors_(props, ref) {

  const { currentUser, clientHits, setClientHits, openClientSearch, checkPermission, clientSubmission, showToast } = useAuth()
  const [clients, setClients] = useState([])
  const [last, setLast] = useState({})
  const [first, setFirst] = useState({})
  const [showPagination, setShowPagination] = useState(false)
  const formatDate = Moment().format("MMM Do, YYYY");
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [clientLoading, setClientLoading] = useState(false)
 

  const history = useHistory()

  const handleClose = () => setShowModal(false);



  useEffect(() => {

    const q = query(collection(db, "debtors"), orderBy("date", "desc"))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempDocs = [];
      querySnapshot.forEach((doc) => {
        tempDocs.push(doc)
      });

      if (tempDocs.length > 10) {
        setShowPagination(true)
      }


    })


  }, [])




  useEffect(() => {

    const q = query(collection(db, "debtors"), orderBy("date", "desc"), limit(10))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });

      setClients(tempData)

      setLast(tempDocs[tempDocs.length - 1])
      setFirst(tempDocs[0])

      setLoading(false)


    });



  }, [])



  /// function for next page pagination
  function nextPage() {

    // if (contracts.length == 11){

    const q = query(collection(db, "debtors"), orderBy("date", "desc"), startAfter(last), limit(10))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });

      if (tempDocs.length > 0) {
        setLast(tempDocs[tempDocs.length - 1])
        setFirst(tempDocs[0])
      }



      setClients(tempData)

    });


  }
  /// function for last page pagination

  function prevPage() {

    const q = query(collection(db, "debtors"), orderBy("date", "desc"), endBefore(first), limitToLast(10))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      const tempDocs = []
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data())
        tempDocs.push(doc)
      });

      if (tempDocs.length > 0) {
        setLast(tempDocs[tempDocs.length - 1])
        setFirst(tempDocs[0])
      }

      setClients(tempData)

    });

  }


  const handleOptions = (label) => {
    if(!checkPermission(label)) {
      // showToast(false,"Permission Denied")
      return true;
    }
    else return false
  }

  // Helper function to format status display
  const formatStatus = (status) => {
    const displayStatuses = [
      "Needs Supporting Documentation",
      "Subscribed Payment Plan",
      "Paid Creditor Directly",
      "Paid Remaining Balance (WON)",
      "Promise-to-Pay (PTP)",
      "Placed With Attorney",
      "Deceased",
      "Bankruptcy",
    ];
    
    return displayStatuses.includes(status) ? status : "In Progress";
  };


  return (
    <>
      {clientLoading ? <LoadingSpinnerWithText text={"Creating Client..."} /> :
        <PlasmicDebtorsList root={{ ref }} {...props}
         addDebtorsButton={{
          onClick: () => {
            if(!checkPermission("Add Debtor")){
              showToast(false,"Permission Denied")
              return;
            }
            history.push("/submitdebts")
          }
         }}
          searchVertStack={{
            children:
              <>
                <InstantSearch searchClient={searchClient} indexName="debts"  >
                  <Configure />
                  <SearchBox className="searchbox" placeholder="Search..." />
                  <CustomHits />
                </InstantSearch>
              </>
          }}
          clientsListStack={{
            children:
            <>
                {openClientSearch ? clientHits.map(x => {
                    return <MasterClientsListCard 
                      key={x.id} 
                      clientName={x.debtorName} 
                      clientEmail={(x.email || x.phoneNumber) ? 
                        (x.email === "" ? x.phoneNumber : x.email) : 
                        (x.contactFields[0].email === "" ? x.contactFields[0].phone : x.contactFields[0].email)} 
                      date={x.displayDate} 
                      status={formatStatus(x.status)}
                      id={x.id} 
                    />

                }) : clients.map(x => {
                  return <MasterClientsListCard 
                    key={x.id} 
                    clientName={x.debtorName} 
                    clientEmail={(x.email || x.phoneNumber) ? 
                      (x.email === "" ? x.phoneNumber : x.email) : 
                      (x.contactFields[0].email === "" ? x.contactFields[0].phone : x.contactFields[0].email)} 
                    date={x.displayDate} 
                    status={formatStatus(x.status)}
                    id={x.id} 
                  />
                })}
              </>
          }}
          paginationStack={{
            wrap: node => showPagination ? node : null
          }}
          nextButton={{
            onClick: () => nextPage()
          }}
          prevButton={{
            onClick: () => prevPage()
          }}
          vertStack={loading && {
            children: <LoadingSpinner />
          }}
        />}
    </>)
}

const AllDebtors = React.forwardRef(AllDebtors_);

export default AllDebtors;