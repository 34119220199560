import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./VerifyDebtorPaymentModal.css";

function VerifyDebtorPaymentModal({ show, onHide, debtorName, creditorName, onAccept, onReject }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      className="verify-payment-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Verify Debtor Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {debtorName} has claimed to have paid {creditorName} directly. 
          Please review your records again and accept or reject the debtor's claim.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button 
          variant="success" 
          onClick={onAccept}
          className="verify-button"
        >
          ✓ Accept
        </Button>
        <Button 
          variant="danger" 
          onClick={onReject}
          className="verify-button"
        >
          ✕ Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerifyDebtorPaymentModal; 