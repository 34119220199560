import { config } from "../components/constants";

const dromoConfig = {

    settings: {
        version: "v2",
        autoMapHeaders: true,
        styleOverrides: {
          primaryButton: {
            backgroundColor: "#14377F",
            hoverBackgroundColor: "#1b459e"
          },
          stepperBar: {
            currentColor: "#14377F"
          }
        },
        backendSyncMode: "FULL_DATA",
        displayEncoding: false,
        allowCustomFields: false,
        invalidDataBehavior: "REMOVE_INVALID_ROWS",
        manualInputDisabled: true,
        templateDownloadFilename: "template.csv",
        allowEmptySubmit: false,
        developmentMode : config.DROMO_DEVELOPMENT_MODE,
      },

    fields: [
        {
          key: "account_number",
          type: "string",
          label: "Account Number",
          validators: []
        },
        {
          key: "debtor_name",
          type: "string",
          label: "Debtor Name",
          validators: [
            {
              validate: "required"
            }
          ]
        },
        {
          key: "amount_of_unpaid_debt",
          type: "string",
          label: "Total Amount of Unpaid Debt",
          validators: [
            {
              validate: "required"
            }
          ]
        },
        {
          key: "age_of_the_debt",
          type: "string",
          label: "Delinquency Date",
          validators: [
            {
              validate: "required"
            }
          ]
        },
        {
          key: "phone_number",
          type: "string",
          label: "Phone Number",
          validators: [
            {
              validate: "required"
            }
          ]
        },
        {
          key: "primary_email",
          type: "string",
          label: "Email",
          validators: [
            {
              validate: "required"
            }
          ]
        },
        {
          key: "product_name",
          type: "string",
          label: "Name of product/service delivered to the debtor",
          validators: [
            {
              validate: "required"
            }
          ]
        },
        {
          key: "creditor_name",
          type: "string",
          label: "Creditor Name",
          validators: [
            {
              validate: "required"
            }
          ]
        },
        {
          key: "address",
          type: "string",
          label: "Address",
          validators: []
        },
        {
          key: "city",
          type: "string",
          label: "City",
          validators: []
        },
        {
          key: "state",
          type: "string",
          label: "State",
          validators: []
        },
        {
          key: "zip_code",
          type: "string",
          label: "Zip Code",
          validators: []
        },
        {
          key: "ssn",
          type: "string",
          label: "SSN",
          validators: []
        },
        {
          key: "last_pay_date",
          type: "string",
          label: "Last Pay Date (if available)",
          validators: []
        },
        {
          key: "charge_off_date",
          type: "string",
          label: "Charge Off Date (if applicable)",
          validators: []
        },
        {
          key: "interest_due",
          type: "string",
          label: "Interest Due",
          validators: []
        },
        {
          key: "principal_due",
          type: "string",
          label: "Principal Due",
          validators: []
        },
        {
          key: "other_fees",
          type: "string",
          label: "Other Fees",
          validators: []
        },
        {
          key: "amount_paid",
          type: "string",
          label: "Amount Paid",
          validators: []
        },
        {
          key: "monthlyInstallmentMinimum",
          type: "string",
          label: "Monthly Installment Minimum",
          validators: [],
        },
        {
          key: "monthlyDownpaymentMinimum",
          type: "string",
          label: "Monthly Downpayment Minimum",
          validators: [],
        },
        {
          key: "lumpsumSettlementMinimum",
          type: "string",
          label: "Lumpsum Settlement Minimum",
          validators: [],
        },
        {
          key: "agencyName",
          type: "string",
          label: "Agency Name",
          validators: []
        },
        {
          key: "rpc",
          type: "checkbox",
          label: "RPC",
          validators: []
        },
        {
          key: "skipTraced",
          type: "checkbox",
          label: "Skip Traced",
          validators: []
        }
      ],
  };

  export default dromoConfig;